@import "variables";

.-Control {
	border-radius: 4px;

	display: inline-block;
	//display: block;
	vertical-align: middle;

	background: white;
	line-height: 20 / 14;
	height: 2.4em;
	padding: 0.35em 0.15em 0.35em 0.3em;
	margin: 2px;

	border: 1px solid @border-color;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	box-sizing: border-box;
	// transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.-SelectList {
	&:extend(select all);
	li {
		display: block;
		padding: 0 2px 1px;
		cursor: default;
		&:hover {
			background-color: @form-hover-background;
		}

		&.selected {
			color: white;
			background-color: @form-selected-background;
		}

		&.split {
			margin-top: 2px;
			padding-top: 2px;
			border-top: 1px @border-color solid;
		}
	}
}

.-Tabs {
	outline: none;
	.tabs {
		width: 100%;
		a {
			padding: 8px 12px;
			margin-right: 2px;
			line-height: 14px;
			display: inline-block;
			cursor: pointer;
			text-align: center;
			background-color: #eee;
			border: 1px solid transparent;
			border-radius: 4px 4px 0 0;
			&:hover {
				background-color: #9dc2d0;
			}
			&.active {
				border: 1px solid #ddd;
				border-bottom-color: transparent;
				background-color: white;
			}
		}
	}
	.tabs-content {}
}

.-Accordion {
	margin: 0.3em;

	.ac_tab {
		margin-top: 2px;
		& > a:first-child {
			z-index: 3;

			padding: 6px 0;
			display: block;
			cursor: pointer;
			text-align: center;
			background-color: #eee;
			border: 1px solid #ccc;
			transition: border-radius 0.1s linear;
			&:before {
				margin-left: 30px;
				float: left;
			}
			&:hover {
				background-color: #9dc2d0;
			}
		}

		& > *:nth-child(2) { // child content
			overflow: hidden;
			transition-property: height, padding; // need "height" in child comp. for working transition
			transition-duration: 0.5s;
			transition-timing-function: linear;
		}
	}

	.ac_tab.active { // active tab
		& > a:first-child {
			border-radius: 6px 6px 0 0;
			border-bottom: 0;
			transition-delay: 0s;
			&:before {
				content: '−';
			}
		}
	}

	.ac_tab.hidden {
		& > a:first-child {
			border-radius: 6px;
			transition-delay: 0.4s;
			&:before {
				content: '+';
			}
		}
		& > *:nth-child(2) { // hidden element
			height: 0;
			padding: 0;
			border: 0;
		}
	}
}

.-Measure {
	background-color: white;
	border: @border-color 1px solid;
	border-radius: 0 5px;
	width: 8em;
	height: 1.2em;
	padding: .3em;
	text-align: center;
	opacity: 0;
	transition: opacity 0.5s;
}
